import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { TagKey } from '@cof/tag-catalog-white-label-ui';
import './ConfirmationPage.scss';
import { useEffect } from 'react';
import checkCircle from '@/assets/icons/CheckCircle.svg';
import LandingPageHeader from '@/components/landing-page-header/landing-page-header';
import { publishTag } from '@/services/tagging-service';
export default ({ metadata }) => {
    useEffect(() => {
        document.title = 'Confirmation';
        publishTag(TagKey.PAGE_VIEW_22);
    }, []);
    const LandingPageContent = metadata?.deeplink?.LandingPageContentText?.split('\n');
    return (_jsxs(_Fragment, { children: [metadata.dealerName && _jsx(LandingPageHeader, { dealerName: metadata.dealerName }), _jsx("div", { className: "ldp-confirmation-container", children: _jsxs("div", { className: "ldp-confirmation-content", children: [_jsx("img", { src: checkCircle, alt: "" }), LandingPageContent && (_jsxs(_Fragment, { children: [_jsx("h1", { className: "fs-heading-text--xlarge", "data-testid": "confirmation-header", children: _jsx(_Fragment, { children: LandingPageContent[0] }) }), _jsx("p", { className: "fs-body-text--normal", children: LandingPageContent[1] })] }))] }) })] }));
};
