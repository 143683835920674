/* eslint-disable @typescript-eslint/restrict-plus-operands */
import qs from 'query-string';
import { constructUrl } from './service-config';
const { prefix = '' } = window.ENV_DATA || {};
const AUTHORIZATION_CODE = 'authorization_code';
const CODE = 'code';
const OAUTH_PROXY_ENDPOINT = `${prefix}`;
const AUTHORIZE_URL = '/oauth2/authorize';
const NC_UNIVERSAL_OPENID = 'ncUniversalId openid';
const { origin } = window.location;
// state - used to point where to land post login
// customerReferenceId - for auto login, pass customerReferenceId
export const buildRedirectUrl = () => window?.ENV_DATA?.redirectHostname
    ? `https://${window.ENV_DATA.redirectHostname}${constructUrl(prefix, '/protected/26699/auto/~/customers/auth')}`
    : `${origin}${constructUrl(prefix, '/protected/26699/auto/~/customers/auth')}`;
export const buildAuthorizeUrl = (params) => qs.stringifyUrl({
    query: {
        client_id: window.ENV_DATA.enterpriseClientId,
        grant_type: AUTHORIZATION_CODE,
        redirect_uri: buildRedirectUrl(),
        response_type: CODE,
        scope: NC_UNIVERSAL_OPENID,
        ...params
    },
    // skipNull: true, removed for lack of support in typescript
    url: params.url ? params.url : `${constructUrl(OAUTH_PROXY_ENDPOINT, AUTHORIZE_URL)}`
});
const buildSignUpUrl = () => window.location.href.replace(window.location.origin, '');
export const buildState = (url = '', dealerId, shortName, vin, deepLinkId, user, leadProvider) => {
    const urlObj = new URL(url);
    const hostName = urlObj.host;
    // Set PQ MFE Flags
    const queryParams = qs.parse(urlObj.search);
    if (user?.isEnterpriseUser === false && user?.prequalification?.isPrequalified) {
        queryParams.showPrequal = 'true';
        queryParams.prequalConsent = 'true';
        window.history.replaceState(null, '', `?${qs.stringify(queryParams)}`);
    }
    const pathName = `${urlObj.pathname}?${qs.stringify(queryParams)}`;
    const stateJSON = {
        hostname: hostName,
        idpType: 'enterprise',
        path: pathName,
        dealerId,
        vin: shortName === 'impactersauto' ? null : vin ? vin : shortName ? getVinFromUrl(shortName) : null,
        deepLinkId,
        leadProvider: leadProvider
    };
    const stateJSONString = JSON.stringify(stateJSON);
    return window.btoa(stateJSONString);
};
export const enhanceState = (state, shortName, deepLinkId, leadProvider) => {
    state.vin || (state.vin = getVinFromUrl(shortName));
    state.deepLinkId = deepLinkId;
    state.leadProvider = leadProvider;
    return window.btoa(JSON.stringify(state));
};
export const getVinFromUrl = (shortName) => {
    const url = window.location.href;
    if (url.includes('vehicle-details')) {
        if (shortName === 'impactersauto')
            return null;
        const urlList = url.split('/');
        return urlList[urlList.length - 1].split('?')[0];
    }
    return null;
};
export const getLoginUrl = ({ state, customerReferenceId, vin, lid, user, webApplicationId, dealerIds, shortName, leadProvider }) => buildAuthorizeUrl({
    applicationId: webApplicationId,
    idpType: 'enterprise',
    redirect_uri: buildRedirectUrl(),
    registration_token: customerReferenceId ? customerReferenceId : undefined,
    signUpUrl: buildSignUpUrl(),
    state: state
        ? enhanceState(state, shortName, lid, leadProvider)
        : buildState(window.location.href, dealerIds, shortName, vin, lid, user, leadProvider),
    url: window.ENV_DATA.capitalOneGatewayUrl + AUTHORIZE_URL
});
// Used only be EIDP
export const getSignUpUrl = ({ state, customerReferenceId, lid, user, webApplicationId, dealerIds, shortName, leadProvider }) => buildAuthorizeUrl({
    applicationId: webApplicationId,
    idpType: 'enterprise',
    prompt: 'create',
    redirect_uri: buildRedirectUrl(),
    registration_token: customerReferenceId ? customerReferenceId : undefined,
    signUpUrl: buildSignUpUrl(),
    state: state
        ? enhanceState(state, shortName, lid, leadProvider)
        : buildState(window.location.href, dealerIds, undefined, undefined, lid, user, leadProvider),
    url: window.ENV_DATA.capitalOneGatewayUrl + AUTHORIZE_URL
});
export const getLogoutUrl = () => {
    return constructUrl(`/protected/731580/logout?client_id=${window.ENV_DATA.enterpriseClientId}&return_url=${encodeURIComponent(window.location.href)}`);
};
export const refreshSession = async ({ webApplicationId }, lid, leadProvider) => {
    const url = buildAuthorizeUrl({
        applicationId: webApplicationId,
        idpType: 'enterprise',
        prompt: 'none',
        redirect_uri: buildRedirectUrl(),
        state: buildState(window.location.href, undefined, undefined, undefined, lid, undefined, leadProvider)
    });
    await window.fetch(url, { method: 'GET', mode: 'no-cors' });
};
export const setupRefreshInterval = ({ webApplicationId }, secondsToRefresh = 600, lid, leadProvider) => {
    let activityDetected = false;
    const handleRefresh = (lid) => {
        if (activityDetected)
            refreshSession({ webApplicationId }, lid, leadProvider);
        activityDetected = false;
    };
    const setActivityDetected = () => {
        activityDetected = true;
    };
    document.onmousemove = setActivityDetected;
    document.onkeydown = setActivityDetected;
    document.onmousedown = setActivityDetected;
    document.addEventListener('scroll', setActivityDetected, true);
    window.setInterval(() => handleRefresh(lid), secondsToRefresh * 1000);
};
export const refreshSessionForFSIDP = async () => {
    const prequalOrchestrator = document.getElementById('capital-one-prequalification-orchestrator');
    prequalOrchestrator?.contentWindow?.postMessage('capital-one-digital-retail-refresh-session', prequalOrchestrator?.src);
};
export const setupRefreshIntervalForFSIDP = (secondsToRefresh = 600) => {
    let activityDetected = false;
    const handleRefresh = () => {
        if (activityDetected)
            refreshSessionForFSIDP();
        activityDetected = false;
    };
    const setActivityDetected = () => {
        activityDetected = true;
    };
    document.onmousemove = setActivityDetected;
    document.onkeydown = setActivityDetected;
    document.onmousedown = setActivityDetected;
    document.addEventListener('scroll', setActivityDetected, true);
    window.setInterval(() => handleRefresh(), secondsToRefresh * 1000);
};
