import { NewRelicPlugin, STORED_CORRELATION_ID_KEY, StratumService } from '@cof/stratum';
import { Product } from '@/models/product';
import StratumTaggingQueue from '@/utils/StratumTaggingQueue';
import { setLocalStorageValue } from '@/utils/storage-utilities';
const w = window;
setLocalStorageValue(STORED_CORRELATION_ID_KEY, '0');
// only exported for testing in TaggingService.test.tsx ... do not use elsewhere
export let stratum = {};
let isStratumInitialized = false;
let catalogVersion = '';
let product = {};
const publishTagQueue = new StratumTaggingQueue();
function setDealerId(dealerId) {
    w.c1_analytics = {
        domain_identifier: btoa(dealerId)
    };
    setExternalVar('dealerId', dealerId);
}
function parseDataForDealerId(data) {
    if (stratum.getExternalVar('dealerId'))
        return;
    if (data?.dealerId) {
        // eslint-disable-line
        setDealerId(data.dealerId);
    }
    else if (data?.deeplink?.dealerId) {
        // eslint-disable-line
        setDealerId(data.deeplink.dealerId);
    }
    else if (data?.vehicle?.dealerId) {
        // eslint-disable-line
        setDealerId(data.vehicle.dealerId);
    }
    else if (data?.dealerIds) {
        setDealerId(data.dealerIds);
    }
}
export async function instantiateStratum(prdct, callback) {
    if (isStratumInitialized && prdct === product)
        return;
    product = prdct;
    stratum = await getShowroomTC();
    setExternalVar('trackerTagVersion', catalogVersion);
    callback();
    isStratumInitialized = true;
    if (isReadyToPublishTag())
        publishTagQueue.nextJob();
}
const getShowroomTC = () => {
    return import('@cof/tag-catalog-white-label-ui').then(catalog => {
        catalogVersion = catalog.CATALOG_VERSION;
        return new StratumService(catalog.TAG_CATALOG, {
            productName: catalog.PRODUCT_NAME,
            productVersion: catalog.CATALOG_VERSION,
            plugins: [NewRelicPlugin()]
        });
    });
};
const isReadyToPublishTag = () => {
    return (isStratumInitialized &&
        stratum.getExternalVar('dealerId') !== undefined &&
        stratum.getExternalVar('applicationId') !== undefined);
};
export const initTealium = (data) => {
    parseDataForDealerId(data);
    if (window.document.getElementById('init-tealium'))
        return;
    const script = window.document.createElement('script');
    script.src = window.ENV_DATA.oneTagUrl;
    script.async = true;
    script.type = 'text/javascript';
    script.id = 'init-tealium';
    window.document.head.appendChild(script);
    if (isReadyToPublishTag())
        publishTagQueue.nextJob();
};
export const setExternalVar = (key, value) => {
    return stratum.setExternalVar(key, value);
};
export const publishTag = (key, options, callback) => {
    if (!isReadyToPublishTag()) {
        publishTagQueue.enqueue(() => {
            const ret = stratum.publishTag(key, options);
            if (callback !== undefined)
                callback();
            return ret;
        });
        return undefined;
    }
    const ret = stratum.publishTag(key, options);
    if (callback !== undefined)
        callback();
    return ret;
};
export const getAppIdByProduct = (productInput) => {
    let result = '';
    switch (productInput) {
        case Product.Showroom:
            result = 'coaf-privatelabel';
            break;
        case Product.Maestro:
            result = 'maestro';
            break;
        case Product.Muse:
            result = 'muse';
            break;
        default:
            result = 'coaf-privatelabel';
            break;
    }
    return result;
};
export function setOneTagConfigAppIdByProduct(productInput) {
    const appId = getAppIdByProduct(productInput);
    // Setup OneTag config
    window.oneTagConfig = {
        oneTagProfile: 'others',
        oneTagEnv: window.ENV_DATA.oneTagEnv,
        app_id: appId
    };
}
