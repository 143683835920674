import { getLocalStorageValue, getSessionStorageValue, StorageKey, SessionStorageKey } from '../utils/storage-utilities';
import * as ServiceConfig from './service-config';
import { setupRefreshInterval, setupRefreshIntervalForFSIDP } from '@/services/login-service';
import { getSRSessionDataForDealer } from '@/services/storage-service';
const SERVICE_PATH = '/protected/26699/auto';
const headers = ServiceConfig.getBaseHeaders();
const settingsPath = 'customers/settings';
const buildUrl = (applicationId, path) => ServiceConfig.constructUrl(SERVICE_PATH, applicationId, path);
export const getUserSettings = async ({ webApplicationId }, user, lid, leadProvider) => {
    const url = buildUrl(webApplicationId, settingsPath);
    const options = {
        headers: {
            ...headers,
            'X-Message-Id': ServiceConfig.generateUUID()
        },
        method: 'GET'
    };
    const res = await fetch(url, options);
    if (user?.profile) {
        if (res.status === 403 || res.status === 401) {
            return { sessionExpired: true };
        }
    }
    if (res.ok) {
        const userSettings = await res.json();
        if (userSettings?.isEnterpriseUser) {
            setupRefreshInterval({ webApplicationId }, undefined, lid, leadProvider);
        }
        else {
            setupRefreshIntervalForFSIDP();
        }
        return userSettings;
    }
    return undefined;
};
export const shouldUnlock = (user, dealer, unlockOverride) => {
    const params = new URLSearchParams(location.search);
    const overrideLock = params.get('view') === '1';
    if (dealer?.requiredUnlock === false)
        return true;
    return (user?.profile !== undefined ||
        overrideLock ||
        unlockOverride ||
        (getSRSessionDataForDealer(dealer?.dealerIds, StorageKey.LeadId) !== null &&
            getLocalStorageValue(StorageKey.VisitorId, false) !== null &&
            getLocalStorageValue(StorageKey.VisitorId, false).split(',')[0] === dealer?.dealerIds));
};
export const shouldShowExpiredPQModalStatus = (user) => {
    const userHasntDismissed = getSessionStorageValue(SessionStorageKey.ShowExpiredPQModal) !== 'false';
    return isPQExpired(user) && userHasntDismissed;
};
export const isPQExpired = (user) => {
    if (user && user.prequalification && user.prequalification.expirationTimestamp) {
        const expirationDate = new Date(user.prequalification.expirationTimestamp).getTime();
        const currentDate = Date.now();
        return expirationDate < currentDate;
    }
    return false;
};
export const removeURLPersistanceParams = (params, setSearchParams, userSettings) => {
    if (userSettings?.prequalification?.isPrequalified) {
        params.delete('downPayment');
        params.delete('financeTermLength');
        params.delete('tradeInRemainingBalance');
        params.delete('tradeInVehicleValue');
        setSearchParams(params, { replace: true });
    }
    else {
        params.delete('pricingId');
        setSearchParams(params, { replace: true });
    }
};
