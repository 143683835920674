import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { searchBarMfeName } from '@cof/inventory-ui';
import { MFEAdapter } from '@cof/mfe-adapter';
import { mfeAdapter as unifiedCalculatorMFEAdapter } from '@cof/mfe-contract-unified-calculator-web-ui-v3';
import { TagKey } from '@cof/tag-catalog-white-label-ui';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import vinValidator from 'vin-validator';
import { MFEComponentDefinitions } from '@/MFEComponentDefinitions';
import TimeoutModal from '@/components/TimeoutModal/TimeoutModal';
import { DealerContext } from '@/context/DealerContext';
import { MaestroContext } from '@/context/MetadataContext';
import { TaggingContext } from '@/context/TaggingContext';
import { UserContext } from '@/context/UserContext';
import { Product } from '@/models/product';
import ConfirmationPage from '@/pages/ConfirmationPage/ConfirmationPage';
import ErrorPage from '@/pages/ErrorPage/ErrorPage';
import LoadingPage from '@/pages/LoadingPage/LoadingPage';
import MuseLandingPageLazy from '@/pages/MusePremium/Loadable';
import PrequalPageLazy from '@/pages/PrequalPage/Loadable';
import SearchResultsPageLazy from '@/pages/SearchResultsPage/Loadable';
import VehicleDetailsPageLazy from '@/pages/VehicleDetailPage/Loadable';
import { getDealerSettings, getShortNameFromPath } from '@/services/dealer-settings';
import { serviceGetContainer } from '@/services/landing-page-service';
import { DeeplinkCustomerType, LeadType } from '@/services/models/MaestroMetadata';
import { getSRSessionDataForDealer, getStoredVisitorId } from '@/services/storage-service';
import { initTealium, instantiateStratum, publishTag, setExternalVar, setOneTagConfigAppIdByProduct } from '@/services/tagging-service';
import { getUserSettings } from '@/services/user-service';
import { initNewRelicData, logNewRelicAction } from '@/utils/newRelic';
import '@/components/fs-components';
import { PrefillDataContext } from '@/context/PrefillDataContext';
import { getLeadSource } from '@/utils/sourceId';
import { getLoginUrl } from '@/services/login-service';
import { fetchInterceptor } from '@/services/interceptor-service';
import { vdpURLFormatter } from '@/utils/formatters';
import { isEnhancedShowroomEnabled } from '@/services/showroom-enhancements-service';
// Setup Fetch Interceptor for SB environments
const { prefix = '' } = window.ENV_DATA || {};
if (prefix) {
    fetchInterceptor();
}
/* Available templateTypes */
export var TemplateTypes;
(function (TemplateTypes) {
    TemplateTypes["PREQUAL"] = "prequal";
    TemplateTypes["VEHICLESHARE"] = "vehicleshare";
    TemplateTypes["FEEDBACKTODEALERCONFIRMATION"] = "feedbacktodealerconfirmation";
    TemplateTypes["MUSEPREMIUM"] = "musesalespersonpage";
    TemplateTypes["BROWSE"] = "browse";
})(TemplateTypes || (TemplateTypes = {}));
const MUSEPQ = 'PQTS-QR-Code';
/** @returns LID/UUID, if it's in the correct form, or undefined */
export const sanitizeLID = (lid) => /^[\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}$/i.test(lid) ? lid : undefined;
/** @returns LID/UUID from url, i.e /?lid=345634, or undefined */
export const processParamLID = () => sanitizeLID(new URLSearchParams(location.search).get('lid') ?? '');
export const processParamVIN = () => {
    const vin = new URLSearchParams(location.search).get('vin') ?? '';
    return vinValidator.validate(vin) ? vin : undefined;
};
export const getNewRoute = (metadata, dealer) => {
    if (metadata === undefined || Object.keys(dealer).length === 0)
        return '';
    if (metadata.deeplink?.templateType?.toLowerCase() === TemplateTypes.VEHICLESHARE) {
        let path;
        const vehicleData = metadata.vehicle;
        if (vehicleData && Object.keys(vehicleData).length > 0) {
            const year = vehicleData.year;
            const make = vehicleData.make;
            const model = vehicleData.model;
            const vin = vehicleData.vin;
            path = vdpURLFormatter(dealer.shortName, { year, make, model, vin });
        }
        else {
            path = vdpURLFormatter(dealer.shortName, { vin: metadata.deeplink.vin });
        }
        return path;
    }
    if (metadata.deeplink?.templateType?.toLowerCase() === TemplateTypes.PREQUAL)
        return `${dealer.shortName}/prequalify`;
    if (metadata.deeplink?.templateType?.toLowerCase() === TemplateTypes.FEEDBACKTODEALERCONFIRMATION)
        return `${dealer.shortName}/confirmation`;
    if (metadata.deeplink?.templateType?.toLowerCase() === TemplateTypes.BROWSE)
        return `${dealer.shortName}`;
    if (metadata.deeplink?.templateType?.toLowerCase() === TemplateTypes.MUSEPREMIUM)
        return `/muse`;
    return '';
};
export const getSearchParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.toString();
};
initNewRelicData(); // Initialize New Relic
export default () => {
    const lid = processParamLID(); // Get LeadID query param
    instantiateStratum(Product.Showroom, () => {
        setExternalVar('applicationId', lid === undefined ? 'showroom' : lid);
    });
    const [error, setError] = useState(); // undefined=loading, null=success, string=error
    const [metadata, setMetadata] = useState();
    const [dealerName, setDealerName] = useState({ isLoaded: false });
    const [dealer, setDealer] = useState({});
    const dealerContextValue = { dealer, setDealer };
    const [vehicleMetadata, setVehicleMetadata] = useState({});
    const deeplinkMetadataContextValue = {
        maestroMetadata: vehicleMetadata,
        setMaestroMetadata: setVehicleMetadata
    };
    const [envData, setEnvData] = useState(window.ENV_DATA);
    const [showTimeoutModal, setShowTimeoutModal] = useState(false);
    const [user, setUser] = useState({ isLoaded: false });
    const userContextValue = { user, setUser };
    const [product, setProduct] = useState(Product.Showroom);
    const [templateType, setTemplateType] = useState(undefined);
    const productContextValue = { product, setProduct, templateType, setTemplateType };
    const [prefillData, setPrefillData] = useState({});
    const [showroomBrandingEnabled] = useState(lid === undefined);
    const [dapEnabled] = useState(lid === undefined);
    const [unlockOverride, setUnlockOverride] = useState(false);
    const [sourceId, setSourceId] = useState('S-SR-1234567890A-API-0001');
    const [leadProvider, setLeadProvider] = useState('PrivateLabel');
    const [enhancedShowroomEnabled, setEnhancedShowroomEnabled] = useState(undefined);
    const processVehicleShareData = (data) => {
        setMetadata(data);
        setVehicleMetadata(data);
        setSourceId(data.deeplink.sourceId);
        setLeadProvider(data.deeplink.leadProvider);
        setUnlockOverride(true);
        if (data?.customer?.customerId && data?.customer?.customerType === DeeplinkCustomerType.VISITOR) {
            setUser({ customerId: data.customer.customerId });
        }
        else if (data?.lead?.leadId && data?.lead?.leadType === LeadType.CRM) {
            setUser({ customerId: data.lead.leadId });
        }
        else {
            setUser({});
        }
        if (Object.keys(data.vehicle).length === 0) {
            setExternalVar('vin', data.deeplink.vin);
        }
        else {
            setExternalVar('vin', data.vehicle?.vin);
            setExternalVar('dealerId', data.vehicle?.dealerId);
        }
        setError(null);
        unifiedCalculatorMFEAdapter.initializeMFE(window.ENV_DATA.calculatorMfeEnv);
        window.dispatchEvent(new Event('lrm-page-load-success'));
        logNewRelicAction("get-deep-link" /* ACTION.GET_METADATA */, { event: 'success' });
    };
    const handleSignInClick = () => {
        window.open(getLoginUrl({
            dealerIds: dealer?.dealerIds,
            shortName: dealer?.shortName,
            webApplicationId: dealer?.webApplicationId,
            lid: lid ?? null,
            user,
            state: undefined,
            customerReferenceId: undefined,
            vin: undefined,
            leadProvider
        }), '_self');
    };
    const processBrowseData = (data) => {
        setMetadata(data);
        setSourceId(data.deeplink.sourceId);
        setLeadProvider(data.deeplink.leadProvider);
        setUnlockOverride(true);
        setExternalVar('dealerId', data.deeplink.dealerId);
        setError(null);
        logNewRelicAction("get-deep-link" /* ACTION.GET_METADATA */, { event: 'success' });
    };
    const processPrequalificationData = (data) => {
        setMetadata(data);
        setSourceId(data.deeplink.sourceId);
        setLeadProvider(data.deeplink.leadProvider);
        if (Object.keys(data.eligibility).length === 0) {
            const leadSource = getLeadSource(metadata?.deeplink?.sourceId ?? '');
            publishTag(TagKey.VIEW_PREQUAL_LANDING_PAGE, { replacements: { value: leadSource } });
            setError('generic');
        }
        else {
            setMetadata(data);
            setError(null);
            window.dispatchEvent(new Event('lrm-page-load-success'));
        }
        logNewRelicAction("get-deep-link" /* ACTION.GET_METADATA */, { event: 'success' });
    };
    const processFeedbackToDealerConfirmation = (data) => {
        setMetadata(data);
        setSourceId(data.deeplink.sourceId);
        setDealerName(data.dealerName);
        if (data.deeplink.LandingPageContentText === undefined)
            setError('notfound');
        else {
            setError(null);
            logNewRelicAction("get-deep-link" /* ACTION.GET_METADATA */, { event: 'success' });
        }
    };
    const fetchMetadata = (vin) => {
        let currentProduct = Product.Showroom;
        if (lid) {
            serviceGetContainer(lid, vin)
                .then(data => {
                initTealium(data);
                setTemplateType(data?.deeplink?.templateType);
                if (data.deeplink.templateType.toLowerCase() === TemplateTypes.PREQUAL) {
                    processPrequalificationData(data);
                    currentProduct = data.deeplink.productType === MUSEPQ ? Product.Muse : Product.Maestro;
                }
                else if (data.deeplink.templateType.toLowerCase() === TemplateTypes.VEHICLESHARE) {
                    processVehicleShareData(data);
                    currentProduct = Product.Maestro;
                }
                else if (data.deeplink.templateType.toLowerCase() === TemplateTypes.FEEDBACKTODEALERCONFIRMATION) {
                    processFeedbackToDealerConfirmation(data);
                    currentProduct = Product.Maestro;
                }
                else if (data.deeplink.templateType.toLowerCase() === TemplateTypes.BROWSE) {
                    processBrowseData(data);
                    currentProduct = Product.Maestro;
                }
                else if (data.deeplink.templateType.toLowerCase() === TemplateTypes.MUSEPREMIUM) {
                    setMetadata(data);
                    setError(null);
                    currentProduct = Product.Muse;
                }
                else {
                    throw Error(`unknown templateType: ${data.deeplink.templateType}`);
                }
                setProduct(currentProduct);
                setOneTagConfigAppIdByProduct(currentProduct);
            })
                .catch(e => {
                // TODO: HOW DO WE STILL SHOW SHOWROOM IF THIS HAPPENS?
                // deeplink call itself failed, we don't know scenario
                logNewRelicAction("get-deep-link" /* ACTION.GET_METADATA */, { event: 'fail', error: e.message });
                setError('generic');
            });
        }
        else {
            setProduct(currentProduct);
            setOneTagConfigAppIdByProduct(currentProduct);
            setError(null);
        }
    };
    const setPrefillContext = (formData) => {
        setPrefillData({
            ...prefillData,
            address: formData?.address?.value,
            aptUnit: formData?.unit?.value,
            birthday: formData?.dateOfBirth?.value,
            city: formData?.city?.value,
            confirmDetails: formData?.consent?.value,
            email: formData?.email?.value,
            firstName: formData?.firstName?.value,
            lastName: formData?.lastName?.value,
            phoneNumber: formData?.cellPhone?.value,
            state: formData?.state?.value,
            zipCode: formData?.zipCode?.value
        });
    };
    const handleTimeoutModalClosure = () => {
        location.reload();
        setShowTimeoutModal(false);
    };
    useEffect(() => {
        const vin = processParamVIN();
        logNewRelicAction("get-deep-link" /* ACTION.GET_METADATA */, { event: 'started' });
        fetchMetadata(vin);
        if (lid === undefined) {
            setDealerSettings();
        }
    }, []);
    useEffect(() => {
        if (metadata) {
            const shortName = getShortNameFromPath();
            setDealerSettings(shortName ? undefined : metadata.deeplink?.dealerId);
        }
    }, [metadata]);
    useEffect(() => {
        if (dealer !== undefined && Object.keys(dealer).length > 0) {
            initUserSettings(dealer, user);
        }
    }, [dealer]);
    const setDealerSettings = async (dealerId) => {
        const response = await getDealerSettings(dealerId).catch(() => {
            setError('generic');
        });
        setDealer({ ...response, isLoaded: true });
        setEnhancedShowroomEnabled(await isEnhancedShowroomEnabled(response));
    };
    const initUserSettings = async (dealerInput, userInput, lid) => {
        const userSettings = await getUserSettings(dealerInput, userInput, lid ?? null, leadProvider);
        setUser({
            ...userSettings,
            customerId: user?.customerId || getStoredVisitorId() || null,
            isLoaded: true,
            lead: { id: user?.lead?.id || getSRSessionDataForDealer(dealer.dealerIds, 'Lead_Id') }
        });
    };
    useEffect(() => {
        if (envData) {
            const componentDefinitions = MFEComponentDefinitions;
            componentDefinitions[MFEComponentDefinitions.findIndex(mfe => mfe.mfeName === 'prequal-mfe')].bundleUrl =
                window.ENV_DATA.prequalMfeUrl;
            componentDefinitions[MFEComponentDefinitions.findIndex(mfe => mfe.mfeName === 'c1-inventory-search')].bundleUrl =
                window.ENV_DATA.searchMfeBundleUrl;
            componentDefinitions[MFEComponentDefinitions.findIndex(mfe => mfe.mfeName === searchBarMfeName)].bundleUrl =
                window.ENV_DATA.vehicleSearchMfeUrl;
            MFEAdapter.initialize(componentDefinitions);
            MFEAdapter.enableMFEVitals('showroom-mfe-vitals');
        }
    }, [envData]);
    useEffect(() => {
        if (user?.sessionExpired)
            setShowTimeoutModal(true);
    }, [user]);
    useEffect(() => {
        if (dealer?.dealerIds) {
            initTealium(dealer);
        }
    }, [dealer]);
    return (_jsx(_Fragment, { children: error === undefined ? (_jsx(LoadingPage, {})) : error != null ? (_jsx(ErrorPage, { error: error })) : (_jsx(Router, { children: _jsx(TaggingContext.Provider, { value: productContextValue, children: _jsx(DealerContext.Provider, { value: dealerContextValue, children: _jsx(UserContext.Provider, { value: userContextValue, children: _jsxs(PrefillDataContext.Provider, { value: {
                                prefillData,
                                setPrefillData: setPrefillContext
                            }, children: [_jsxs(Routes, { children: [_jsx(Route, { path: "/:shortname/prequalify", element: lid ? (_jsx(PrequalPageLazy, { deeplink: metadata?.deeplink ?? {}, eligibility: metadata?.eligibility ?? {}, lid: lid })) : (_jsx(ErrorPage, { error: 'generic' })) }), _jsx(Route, { path: "/muse", element: _jsx(MuseLandingPageLazy, { metadata: metadata }) }), _jsx(Route, { path: "/:shortname/confirmation", element: _jsx(ConfirmationPage, { metadata: metadata }) }), _jsx(Route, { path: "/", element: _jsx(Navigate, { to: { pathname: getNewRoute(metadata, dealer), search: getSearchParams() }, replace: true }) }), _jsx(Route, { path: "/:shortname", element: _jsx(SearchResultsPageLazy, { dapEnabled: dapEnabled, showroomBrandingEnabled: showroomBrandingEnabled, unlockOverride: unlockOverride, sourceId: sourceId, leadProvider: leadProvider, enhancedShowroomEnabled: enhancedShowroomEnabled, setEnhancedShowroomEnabled: setEnhancedShowroomEnabled }) }), _jsx(Route, { path: "/:shortname/vehicle-details/:year/:make/:model/:vin", element: _jsx(MaestroContext.Provider, { value: deeplinkMetadataContextValue, children: _jsx(VehicleDetailsPageLazy, { dapEnabled: dapEnabled, showroomBrandingEnabled: showroomBrandingEnabled, unlockOverride: unlockOverride, leadProvider: leadProvider, enhancedShowroomEnabled: enhancedShowroomEnabled, setEnhancedShowroomEnabled: setEnhancedShowroomEnabled }) }) }), _jsx(Route, { path: "/:shortname/*", element: _jsx(ErrorPage, { error: "notfound" }) }), _jsx(Route, { path: "/error", element: _jsx(ErrorPage, { error: "notfound" }) })] }), showTimeoutModal && dealer?.theme && (_jsx(TimeoutModal, { handleSignInClick: handleSignInClick, handleClose: handleTimeoutModalClosure, dealer: dealer }))] }) }) }) }) })) }));
};
