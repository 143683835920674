import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import iconCaution from '@/assets/icons/Caution.svg';
import iconError from '@/assets/icons/Error.svg';
import iconExamine from '@/assets/icons/Examine.svg';
import { displayText } from '@/constants/display-text';
import '@/components/Error/Error.scss';
import '@cof/fs-typography-core/lib/typography.css';
export const errors = {
    generic: { img: iconCaution, msg: displayText.ERROR_MSG_GENERIC, suggestion: displayText.ERROR_SUGGESTION_2 },
    vehicle: { img: iconError, msg: displayText.ERROR_MSG_VIN, suggestion: displayText.ERROR_SUGGESTION_1 },
    deeplink: { img: iconExamine, msg: displayText.ERROR_MSG_404, suggestion: displayText.ERROR_SUGGESTION_1 },
    notfound: { img: iconExamine, msg: displayText.ERROR_MSG_404, suggestion: displayText.ERROR_SUGGESTION_2 },
    dealer: { img: iconError, msg: displayText.ERROR_MSG_DEALER, suggestion: displayText.ERROR_SUGGESTION_DEALER }
};
export default ({ variant = 'generic' }) => (_jsxs("div", { className: "ldp-center ldp-error", children: [_jsx("img", { src: errors[variant].img, alt: "icon" }), _jsx("span", { className: "fs-heading-text--medium", "data-testid": "error-message", children: errors[variant].msg }), _jsx("div", { className: "fs-body-text--normal", children: errors[variant].suggestion })] }));
